import React from 'react';
import {
  Row,
  Col
} from 'react-bootstrap';

import instagramLogo from '../../images/instagram-white-logo.png'
import facebookLogo from '../../images/facebook-white-logo.png'
import hanamiMap from '../../images/hanami-map.png'

import './Footer.css';


export default function Footer() {
  return (
    <Row className="footer-row-container">
      <Col lg={4}>
        <div className="footer-first-col">
          <p className="footer-first-col-header1">ÖPPETTIDER</p>
          <p className="footer-first-col-days">MÅNDAG - FREDAG</p>
          <p className="footer-first-col-time">11:00 - 20:00</p>
          <p className="footer-first-col-days">LÖRDAG</p>
          <p className="footer-first-col-time">12:00 - 20:00</p>
          <p className="footer-first-col-days">SÖNDAG</p>
          <p className="footer-first-col-time">Stängt</p>
        </div>
      </Col>
      <Col lg={4}>
        <div className="footer-second-col">
          <a href="https://www.google.com/maps/place/%C3%96stergatan+53,+262+31+%C3%84ngelholm/@56.2461836,12.8639119,17z/data=!3m1!4b1!4m5!3m4!1s0x46522aa18c6febe1:0xe9f50abbae9a4730!8m2!3d56.2461836!4d12.8661006">
            <img src={hanamiMap} alt="hanami-map" className="footer-hanami-map"/>
          </a>
        </div>
      </Col>
      <Col lg={4} className="footer-third-col-container">
        <div className="footer-third-col">
          <p className="footer-first-col-city">ÄNGELHOLM</p>
          <p className="footer-first-col-adress">Östergatan 53</p>
          <p className="footer-first-col-adress">262 31</p>
          <p className="footer-first-col-adress">0431-472999</p>
        </div>
        <div className="footer-social-media-container">
          <a href="https://www.instagram.com/hanamisushi999/">
            <img src={instagramLogo} alt="hanami-logo" className="footer-instagram-logo"/>
          </a>
          <a href="https://www.facebook.com/Hanamisushibar.se">
            <img src={facebookLogo} alt="hanami-logo" className="footer-facebook-logo"/>
          </a>
      </div>
      </Col>
    </Row>
  );
}
