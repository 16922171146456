import React, {useState, useEffect} from 'react';
import {
  Row
} from 'react-bootstrap';

//Components
import MenuCard from '../menuCard/MenuCard';
//Css
import './MenuCardContainer.css';


export default function MenuCardContainer({menu, menuTitle, menuExtraInfo}) {
  const [theMenu, setTheMenu] = useState([]);
// eslint-disable-next-line
  useEffect(() => {
    menuReMapper();
  }, [])

  const menuReMapper = () => {
    const mappedMenu = [];
    let tempArr = [];
    let c = 0;
    menu?.forEach((menuObj, i) => {

      if(!menuObj) return;

      tempArr.push(menuObj)
      c++;
      if(c > 1){
        mappedMenu.push(tempArr);
        c = 0;
        tempArr = []
      }
    });

    if(!menu) return;

    if(menu.length % 2 !== 0) {
      mappedMenu.push([menu[menu.length-1]]);
    }

    setTheMenu(mappedMenu);
  }
  
  return (
    <div className="menuCardContainer-container">
      <h1 className="menuTitle">
        {menuTitle}
      </h1>
      {menuExtraInfo && 
        <h6 className="menuExtraInfo">
          {menuExtraInfo}
        </h6>
      }
      {theMenu.map((menuRow, i) => 
        <Row key={i}>
          {menuRow.map((menuCardInfo, i) => 
            <MenuCard key={i} menuCardInfo={menuCardInfo}/>
          )}
        </Row>
      )}
    </div>
  );
}
