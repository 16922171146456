import React from 'react';

import './LunchMenuContainer.css';

import lunchMenu from '../../menus/lunchMenu.json';

import h_hour from '../../images/h_hour.png';

export default function LunchMenuContainer() {

  return (
    <div className="lunchMenuContainer-container" id="lunchMenuContainer">
      <h1 className="lunchMenuContainer-menuTitle">
        LUNCHMENY
      </h1>

      <h5>
        DAGENS SUSHI
      </h5>
      <h5>
        Måndag - Fredag
      </h5>
      <h5 style={{marginBottom: 40}}>
        11:00-14:00
      </h5>
      {lunchMenu.map((menuCardInfo, i) => 
        <div key={i} className="lunchMenuContainer-menuCard">
          <div className="menuCard-title-price">
            <p className="menuCard-title">
              {menuCardInfo?.title}
            </p>
            <div className="dots"/>
            <p className="menuCard-price">
              {menuCardInfo?.price? menuCardInfo?.price + ' SEK' : ''}
            </p>
          </div>
          <p className="menuCard-ingredients">
            {menuCardInfo?.ingredients}
          </p>
        </div>
      )}

      <div className="lunchMenuContainer-menuCard">
        <p className="menuCard-title">
        </p>
        <p className="menuCard-ingredients">
          Inkl. dryck och misosoppa.
        </p>
      </div>
      <div className="lunchMenuContainer-container" id="lunchMenuContainer"> 
        <h1 className="lunchMenuContainer-menuTitle"> 
        Happy Hour
        </h1>
      <h5>
        Varje dag mellan kl. 16:00 - 17:00 <br/>
      </h5>
      <h6> (Ej byte av bitar)
      {/* <img src={h_hour} alt="h_hour" width='100%' height='auto'/> */}
      </h6>
      </div>
    </div>
  );
}
