import React from 'react';
import {
  Navbar,
  Nav
} from 'react-bootstrap';

import hanamiLogo from '../../images/hanami-logo.png'

import './Navbar.css';

export default function MainNavbar() {
  return (
    <Navbar bg='transparent' expand='lg'>
      <img src={hanamiLogo} alt="hanami-logo" className="navbar-hanami-logo"/>
      <Navbar.Brand href='/' className='nav-logo-text'>Hanami sushi bar</Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto nav-links-menu'>
          <Nav.Link className='nav-link-text' href='/om-oss'>Om oss</Nav.Link>
          <Nav.Link className='nav-link-text' href='/kontakta-oss'>Kontakt</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
