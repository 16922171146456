import React from 'react';

import './AboutPage.css';

import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import AboutCard from '../../components/aboutCard/AboutCard';


export default function AboutPage() {
  return (
    <div className="homePage-container">
      <div className="aboutPage-backgroundImage-container">
        <Navbar />

        <div className="aboutPage-aboutCard-container">
          <AboutCard />
        </div>
      <Footer />
      </div>
    </div>
  );
}
