import React from 'react';

//Json
import menu from '../../menus/menu.json';
//Components
import MenuCardContainer from '../menuCardContainer/MenuCardContainer';
import LunchMenuContainer from '../lunchMenuContainer/LunchMenuContainer';
//Css
import './MenuSheet.css';


export default function MenuSheet() {
  return (
    <div className='menu-sheet-container'>
      <div className='menu-sheet-inner-container'>

        <LunchMenuContainer />
        
        <div className="happy-hour-div" />
        <MenuCardContainer menu={menu.happyhour} menuTitle=" ‎ "/>

        <div className='menu-sheet-extra-info-container'>
          <div>
            <p className='menu-sheet-extra-info'>Vid köp över 450 kr ingår en räkchips påse, så länge lagret räcker.</p>
          </div>
        </div>

        <div className='menu-sheet-extra-info-student-container'>
          <div>
            <p className='menu-sheet-extra-info'>Student = 10% Rabatt</p>
          </div>
        </div>

        <div className="menuCardContainer-nigiri-background" />
        <MenuCardContainer menu={menu.nigiri} menuTitle="NIGIRI" />
        
        <div className="menuCardContainer-sushi-background" />
        <MenuCardContainer menu={menu.sushi} menuTitle="SUSHI"/>

        <div className="menuCardContainer-maki-background" />
        <MenuCardContainer menu={menu.maki} menuTitle="MAKI"/>

        <div className="menuCardContainer-special-maki-background" />
        <MenuCardContainer 
          menu={menu.luxuriousMaki} 
          menuTitle="LYX MAKI" 
          menuExtraInfo="MAKIRULLAR GÖRS VID BESTÄLLNING OCH BESTÅR AV EN HEL RULLE / 10 BITAR, DÄRFÖR GÅR DET TYVÄRR INTE ATT BLANDA OLIKA SORTER"
        />

        <div className="menuCardContainer-sashimi-background" />
        <MenuCardContainer menu={menu.sashimi} menuTitle="SASHIMI"/>

        <div className="menuCardContainer-flambera-background" />
        <MenuCardContainer menu={menu.deluxeMaki} menuTitle="DELUXE MAKI"/>

        <div className="menuCardContainer-poke-bowls-background" />
        <MenuCardContainer menu={menu.pokeBowls} menuTitle="POKE BOWLS"/>

        {/* <div className="menuCardContainer-family-background" />
        <MenuCardContainer menu={menu.familyHanamiSpecial} menuTitle="FAMILJ HANAMI SPECIAL"/>
        <div className="vertical-distance"/> */}

        <div className="menuCardContainer-flambe-background" />
        <MenuCardContainer menu={menu.hotMeals} menuTitle="VARMRÄTTER"/>

        <div className="menuCardContainer-childrens-menu-background" />
        <MenuCardContainer menu={menu.childrensMenu} menuTitle="BARNMENY"/>

        <div className="menuCardContainer-shrimp-chips-background" />
        <MenuCardContainer menu={menu.appetizersAndSideDishes} menuTitle="FÖRRÄTTER & TILLBEHÖR"/>

        <div className="menuCardContainer-drinks-background" />
        <MenuCardContainer menu={menu.drinks} menuTitle="DRYCK"/>

        <div className="bubble-tea-div"/>
        <MenuCardContainer 
          menu={menu.bubbleTea} 
          menuTitle="BUBBLE TEA"
          menuExtraInfo={<div>Dryck med tapiokapärlor, juice balls och frukt jelly <br /> <br />
           Välj mellan te smakerna: Classic, Matcha, Taro <br /> Välj sedan popping bobas (juice balls) enligt följande smaker: </div>}
        />
        

      </div>
    </div>
  );
}
