import React from 'react';

import './ContactPage.css';

import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import ContactCard from '../../components/contactCard/ContactCard';


export default function ContactPage() {
  return (
    <div className="homePage-container">
      <div className="contactPage-backgroundImage-container">
        <Navbar />

        <div className="contactPage-contactCard-container">
          <ContactCard />
        </div>
        <Footer />
      </div>
    </div>
  );
}
