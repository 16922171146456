import React from 'react';
import {
  Row,
  Col
} from 'react-bootstrap';

import downArrow from '../../images/down-arrow.png'

import './AboutCard.css';

import { aboutText1, aboutText2 } from '../../texts/texts.json';


export default function AboutCard() {
  return (
    <div className="aboutCard-container">

      <div className="aboutCard-text-container">
        <div className="aboutCard-img-container">
          <a href="/">
            <img src={downArrow} alt="left-arrow" className="aboutCard-left-arrow"/>
          </a>
        </div>
        
        <h1 className="aboutCard-title">
          OM OSS
        </h1>

        <Row className="aboutCard-row-container">
          <Col lg={6}>
            <h5 className="aboutCard-text">
              {aboutText1}
            </h5>
          </Col>
          <Col lg={6}>
            <h5 className="aboutCard-text">
              {aboutText2}
            </h5>
          </Col>
        </Row>

      </div>
    </div>
  );
}
