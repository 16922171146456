import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import HomePage from './screens/home/HomePage';
import AboutPage from './screens/about/AboutPage';
import ContactPage from './screens/contact/ContactPage';


export default function App() {
  return (
    <Router>
      <div className="content">
        <Switch>
          <Route exact path='/'>
            <HomePage />
          </Route>
          <Route path='/om-oss'>
            <AboutPage />
          </Route>
          <Route path='/kontakta-oss'>
            <ContactPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}
