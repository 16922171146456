import React from 'react';
import {Link} from 'react-scroll'

import './HomePage.css';

import hanamiLogo from '../../images/hanami-logo.png'
import instagramLogo from '../../images/instagram-logo.png'
import facebookLogo from '../../images/facebook-logo.png'
import downArrow from '../../images/down-arrow.png'

import Navbar from '../navbar/Navbar';
import Footer from '../footer/Footer';
import MenuSheet from '../../components/menuSheet/MenuSheet';


export default function HomePage() {
  return (
    <div className="homePage-container">
      <div className="homePage-backgroundImage-container">
        <Navbar />
        
        <div className="homePage-hanami-logo-container">
          <img src={hanamiLogo} alt="hanami-logo" className="homePage-hanami-logo"/>
        </div>

        <div className="homePage-scrollButton-container">
          <Link to="lunchMenuContainer" spy={true} smooth={true}>
            <div className="homePage-innerContainer">
              <p className="homePage-scroll-text">Scrolla till menyn!</p>
              <img src={downArrow} alt="down-arrow" className="homePage-down-arrow"/>
            </div>
          </Link>
        </div>

        <div className="homePage-social-media-container">
            <a href="https://www.instagram.com/hanamisushi99999/">
              <img src={instagramLogo} alt="hanami-logo" className="homePage-instagram-logo"/>
            </a>
            <a href="https://www.facebook.com/profile.php?id=61557294257313">
              <img src={facebookLogo} alt="hanami-logo" className="homePage-facebook-logo"/>
            </a>
        </div>

      </div>

      <MenuSheet />
      
      <Footer />
    </div>
  );
}
