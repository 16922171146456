import React from 'react';
import {
  Row
} from 'react-bootstrap';

import downArrow from '../../images/down-arrow.png'

import './ContactCard.css';

import { contactUsText } from '../../texts/texts.json';


export default function ContactCard() {
  return (
    <div className="contactCard-container">

      <div className="contactCard-text-container">
        <div className="contactCard-img-container">
          <a href="/">
            <img src={downArrow} alt="left-arrow" className="contactCard-left-arrow"/>
          </a>
        </div>

        <h1 className="contactCard-title">
          KONTAKTA OSS
        </h1>

        <Row className="contactCard-row-container">
          <h5 className="contactCard-text">
            {contactUsText}
          </h5>

          <div className="contactCard-contact-container">
            <h5 className="contactCard-phone-text">
              Telefon:
              <a href="tel:0431-472999" className="contactCard-phone-a-text">
                0431-472999
              </a>
            </h5>
            <h5 className="contactCard-mail-text">
              E-post:
              <a href="mailto:kontakt@hanamisushibar.se" className="contactCard-mail-a-text">
                kontakt@hanamisushibar.se
              </a>
            </h5>
          </div>
        </Row>

      </div>
    </div>
  );
}
