import React from 'react';
import {
  Col
} from 'react-bootstrap';

import './MenuCard.css';

export default function MenuCard({menuCardInfo}) {
  return (
    <Col lg={6} className="menuCard-container">
      <div className="menuCard-title-price">
        <p className="menuCard-title">
          {menuCardInfo?.title}
        </p>
        <div className="dots"/>
        <p className="menuCard-price">
          {menuCardInfo?.price} SEK
        </p>
      </div>

      {
        menuCardInfo?.ingredientsArray?.map((ingredientsText, i) => 
        <p key={i} className="menuCard-ingredients">
          {ingredientsText}
        </p>
        )
      }
    </Col>
  );
}
